import React from 'react';
import { NextPage, GetServerSideProps } from 'next';
import styled from 'styled-components';
import { Element as ScrollElement } from 'react-scroll';
import { BambooHRJobOpenings, getJobsFromBambooHR } from '../api/bamboohr';
import Container from '../components/Container';
import Head from '../components/Head';
import { P2, H3 } from '../components/Typography';
import 'flickity/css/flickity.css';

import { retrievePosthogFlagBagServerSide } from '../utils/flagging';
import CareerMasthead from '../components/pages/careers/CareerMasthead';
import CompanyMission from '../components/pages/careers/CompanyMission';
import CompanyValues from '../components/pages/careers/CompanyValues';
import CareerCards from '../components/pages/careers/CareerCards';
import CompanyPerks from '../components/pages/careers/CompanyPerks';
import JobBoard from '../components/pages/careers/JobBoard';

const CareersWrapper = styled.div`
  padding-bottom: 0px;
`;

const Section = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 72px auto 0px auto;
  }
`;

const RemoteFTSection = styled(Section)`
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 72px 16px 36px;
    text-align: left;
  }
`;

const SectionBody = styled(P2)`
  margin: 24px 0 40px;
  max-width: 600px;
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 24px 16px 0px;
    text-align: left;
  }
`;

const ProfilesImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 40px;
  margin-right: 40px;
`;

const ProfilesImage = styled.img`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.maxContent}px;
  margin-top: 16px;
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const MobileImageDiv = styled.div`
  max-width: 100%;
  margin: auto 16px auto 14px;
  justify-content: center;
`;

const ProfilesImageMobile = styled.img`
  width: 100%;
  max-width: 660px;
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`;

const PrimaryColorH3Header = styled(H3)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0px 16px;
    text-align: left;
  }
  color: ${({ theme }) => theme.colors.primary};
`;

type CareerPageProps = {
  jobApiResult: BambooHRJobOpenings[];
};

const CareersPage: NextPage<CareerPageProps> = ({ jobApiResult }: CareerPageProps) => (
  <>
    <Head
      title="Careers"
      url="/careers"
      description="We're creating a platform for Creators to rapidly launch exciting products their Fans love - and make a living out of it."
    />
    <CareersWrapper>
      <CareerMasthead />
      <CompanyMission />
      <CompanyValues />
      <CompanyPerks />
      <RemoteFTSection>
        <PrimaryColorH3Header>We Are a 100% Remote Team</PrimaryColorH3Header>
        <SectionBody>
          We understand that the best people are spread around the world. So we want to meet you where you are
          (virtually)! Work the way that fits best with your lifestyle and your needs.
        </SectionBody>
      </RemoteFTSection>
      <ProfilesImageWrapper>
        <ProfilesImage src="/assets/careers/careers-profiles.png" />
      </ProfilesImageWrapper>
      <MobileImageDiv>
        <ProfilesImageMobile src="/assets/careers/careers-profiles-mobile.png" />
      </MobileImageDiv>
      <CareerCards />
      <ScrollElement name="openJobs" />
      <JobBoard openJobs={jobApiResult} />
    </CareersWrapper>
  </>
);

export const getServerSideProps: GetServerSideProps<CareerPageProps> = async (ctx) => {
  const jobApiResult = await Promise.resolve(getJobsFromBambooHR());
  const flagBag = await retrievePosthogFlagBagServerSide(ctx);

  return {
    props: {
      jobApiResult,
      flagBag,
    },
  };
};

export default CareersPage;
